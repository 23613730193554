.wrap {
	display: flex;
	flex-direction: column;
	gap: var(--s16);
	.wrapSections {
		display: flex;
		flex-direction: column;
		gap: var(--s32);
	}
}
