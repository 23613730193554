@use '@/styles' as *;
.headerSetting {
	display: flex;
	align-items: center;
	justify-content: space-between;
	.title {
		display: flex;
		align-items: center;
		gap: var(--s8);
		svg {
			@include svgSize();
		}
		> div {
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}
		.back {
		}
		.reload {
			&:hover {
				transform: rotate(180deg) scale(1.1);
			}
		}
		.lang {
			width: var(--s100);
			height: var(--s40);
		}
	}
}
