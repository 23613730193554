.wrapper {
	position: relative;
	display: flex;
	flex: 1;
	width: 100%;
	height: 100%;
}

.selector {
	width: 100%;
	padding: 0 var(--s8) var(--s8) var(--s8);
	border: 1px solid var(--casino-blue-grey-1);
	border-radius: var(--s12);
	overflow: hidden;
	transition: height 0.3s ease;
	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 100;
}
