@use '@/styles' as *;
.dropWrapper {
	width: 100%;
	height: 100%;
	border: var(--s2) solid var(--casino-blue-grey-1);
	background-color: var(--casino-bg-blue-purple);
	border-radius: var(--s16);
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: var(--s8);
	overflow: hidden;
	cursor: pointer;
	transition:
		background-color 0.2s ease-in-out,
		border 0.2s ease-in-out;
	svg {
		@include svgSize();
	}
	.empty {
		width: var(--s250);
		height: var(--s150);
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.wrapImag {
		width: 100%;
		height: 100%;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.dropTitle {
		color: #fff;
		opacity: 0.6;
		font-size: var(--s13);
		text-align: center;
		line-height: var(--s16);
	}
	&:hover {
		background-color: #0d171e;
	}
	& input[type='file'] {
		display: none;
	}
	> img {
		width: 100%;
		height: 100%;
		border-radius: var(--s14);
	}
	.menu {
		display: flex;
		align-items: center;
		gap: var(--s16);
		position: absolute;
		background-color: #0d181d;
		border-radius: var(--s12);
		right: var(--s12);
		top: var(--s8);
		padding: var(--s8) var(--s12);
	}
}
