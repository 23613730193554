.common {
	display: flex;
	flex-direction: column;
	gap: var(--s24);
	.inputs {
		width: var(--s624);
		display: flex;
		flex-direction: column;
		gap: var(--s16);
		.up {
			display: flex;
			gap: var(--s24);
		}
		.down {
			display: flex;
			gap: var(--s24);
		}
	}
	.selects {
		display: flex;
		flex-direction: column;
		gap: var(--s16);
	}
	.inputs_bonuses {
		display: flex;
		gap: var(--s24);
		.bonus_title {
			flex-basis: 35%;
		}
		.bonus_description {
			flex-basis: 65%;
		}
	}
}

.itemWithIcon {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: var(--s4);
	img {
		width: var(--s24);
		height: var(--s24);
	}
}
