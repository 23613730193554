:root {
	--s1: calc(0.7vw);
	--s2: calc(0.14vw);
	--s3: calc(0.21vw);
	--s4: calc(0.28vw);
	--s5: calc(0.35vw);
	--s6: calc(0.42vw);
	--s7: calc(0.49vw);
	--s8: calc(0.56vw);
	--s9: calc(0.63vw);
	--s10: calc(0.7vw);
	--s11: calc(0.77vw);
	--s12: calc(0.84vw);
	--s13: calc(0.91vw);
	--s14: calc(0.98vw);
	--s15: calc(1.05vw);
	--s16: calc(1.12vw);
	--s17: calc(1.19vw);
	--s18: calc(1.26vw);
	--s19: calc(1.33vw);
	--s20: calc(1.4vw);
	--s21: calc(1.47vw);
	--s22: calc(1.54vw);
	--s23: calc(1.61vw);
	--s24: calc(1.68vw);
	--s25: calc(1.75vw);
	--s26: calc(1.82vw);
	--s27: calc(1.89vw);
	--s28: calc(1.96vw);
	--s29: calc(2.03vw);
	--s30: calc(2.1vw);
	--s31: calc(2.17vw);
	--s32: calc(2.24vw);
	--s33: calc(2.31vw);
	--s34: calc(2.38vw);
	--s35: calc(2.45vw);
	--s36: calc(2.52vw);
	--s37: calc(2.59vw);
	--s38: calc(2.66vw);
	--s39: calc(2.73vw);
	--s40: calc(2.8vw);
	--s41: calc(2.87vw);
	--s42: calc(2.94vw);
	--s43: calc(3.01vw);
	--s44: calc(3.08vw);
	--s45: calc(3.15vw);
	--s46: calc(3.22vw);
	--s47: calc(3.29vw);
	--s48: calc(3.36vw);
	--s49: calc(3.43vw);
	--s50: calc(3.5vw);
	--s51: calc(3.57vw);
	--s52: calc(3.64vw);
	--s53: calc(3.71vw);
	--s54: calc(3.78vw);
	--s55: calc(3.85vw);
	--s56: calc(3.92vw);
	--s57: calc(3.99vw);
	--s58: calc(4.06vw);
	--s59: calc(4.13vw);
	--s60: calc(4.2vw);
	--s61: calc(4.27vw);
	--s62: calc(4.34vw);
	--s63: calc(4.41vw);
	--s64: calc(4.48vw);
	--s65: calc(4.55vw);
	--s66: calc(4.62vw);
	--s67: calc(4.69vw);
	--s68: calc(4.76vw);
	--s69: calc(4.83vw);
	--s70: calc(4.9vw);
	--s71: calc(4.97vw);
	--s72: calc(5.04vw);
	--s73: calc(5.11vw);
	--s74: calc(5.18vw);
	--s75: calc(5.25vw);
	--s76: calc(5.32vw);
	--s77: calc(5.39vw);
	--s78: calc(5.46vw);
	--s79: calc(5.53vw);
	--s80: calc(5.6vw);
	--s81: calc(5.67vw);
	--s82: calc(5.74vw);
	--s83: calc(5.81vw);
	--s84: calc(5.88vw);
	--s85: calc(5.95vw);
	--s86: calc(6.02vw);
	--s87: calc(6.09vw);
	--s88: calc(6.16vw);
	--s89: calc(6.23vw);
	--s90: calc(6.3vw);
	--s91: calc(6.37vw);
	--s92: calc(6.44vw);
	--s93: calc(6.51vw);
	--s94: calc(6.58vw);
	--s95: calc(6.65vw);
	--s96: calc(6.72vw);
	--s97: calc(6.79vw);
	--s98: calc(6.86vw);
	--s99: calc(6.93vw);
	--s100: calc(7vw);
	--s101: calc(7.07vw);
	--s102: calc(7.14vw);
	--s103: calc(7.21vw);
	--s104: calc(7.28vw);
	--s105: calc(7.35vw);
	--s106: calc(7.42vw);
	--s107: calc(7.49vw);
	--s108: calc(7.56vw);
	--s109: calc(7.63vw);
	--s110: calc(7.7vw);
	--s111: calc(7.77vw);
	--s112: calc(7.84vw);
	--s113: calc(7.91vw);
	--s114: calc(7.98vw);
	--s115: calc(8.05vw);
	--s116: calc(8.12vw);
	--s117: calc(8.19vw);
	--s118: calc(8.26vw);
	--s119: calc(8.33vw);
	--s120: calc(8.4vw);
	--s121: calc(8.47vw);
	--s122: calc(8.54vw);
	--s123: calc(8.61vw);
	--s124: calc(8.68vw);
	--s125: calc(8.75vw);
	--s126: calc(8.82vw);
	--s127: calc(8.89vw);
	--s128: calc(8.96vw);
	--s129: calc(9.03vw);
	--s130: calc(9.1vw);
	--s131: calc(9.17vw);
	--s132: calc(9.24vw);
	--s133: calc(9.31vw);
	--s134: calc(9.38vw);
	--s135: calc(9.45vw);
	--s136: calc(9.52vw);
	--s137: calc(9.59vw);
	--s138: calc(9.66vw);
	--s139: calc(9.73vw);
	--s140: calc(9.8vw);
	--s141: calc(9.87vw);
	--s142: calc(9.94vw);
	--s143: calc(10.01vw);
	--s144: calc(10.08vw);
	--s145: calc(10.15vw);
	--s146: calc(10.22vw);
	--s147: calc(10.29vw);
	--s148: calc(10.36vw);
	--s149: calc(10.43vw);
	--s150: calc(10.5vw);
	--s151: calc(10.57vw);
	--s152: calc(10.64vw);
	--s153: calc(10.71vw);
	--s154: calc(10.78vw);
	--s155: calc(10.85vw);
	--s156: calc(10.92vw);
	--s157: calc(10.99vw);
	--s158: calc(11.06vw);
	--s159: calc(11.13vw);
	--s160: calc(11.2vw);
	--s161: calc(11.27vw);
	--s162: calc(11.34vw);
	--s163: calc(11.41vw);
	--s164: calc(11.48vw);
	--s165: calc(11.55vw);
	--s166: calc(11.62vw);
	--s167: calc(11.69vw);
	--s168: calc(11.76vw);
	--s169: calc(11.83vw);
	--s170: calc(11.9vw);
	--s171: calc(11.97vw);
	--s172: calc(12.04vw);
	--s173: calc(12.11vw);
	--s174: calc(12.18vw);
	--s175: calc(12.25vw);
	--s176: calc(12.32vw);
	--s177: calc(12.39vw);
	--s178: calc(12.46vw);
	--s179: calc(12.53vw);
	--s180: calc(12.6vw);
	--s181: calc(12.67vw);
	--s182: calc(12.74vw);
	--s183: calc(12.81vw);
	--s184: calc(12.88vw);
	--s185: calc(12.95vw);
	--s186: calc(13.02vw);
	--s187: calc(13.09vw);
	--s188: calc(13.16vw);
	--s189: calc(13.23vw);
	--s190: calc(13.3vw);
	--s191: calc(13.37vw);
	--s192: calc(13.44vw);
	--s193: calc(13.51vw);
	--s194: calc(13.58vw);
	--s195: calc(13.65vw);
	--s196: calc(13.72vw);
	--s197: calc(13.79vw);
	--s198: calc(13.86vw);
	--s199: calc(13.93vw);
	--s200: calc(14vw);
	--s201: calc(14.07vw);
	--s202: calc(14.14vw);
	--s203: calc(14.21vw);
	--s204: calc(14.28vw);
	--s205: calc(14.35vw);
	--s206: calc(14.42vw);
	--s207: calc(14.49vw);
	--s208: calc(14.56vw);
	--s209: calc(14.63vw);
	--s210: calc(14.7vw);
	--s211: calc(14.77vw);
	--s212: calc(14.84vw);
	--s213: calc(14.91vw);
	--s214: calc(14.98vw);
	--s215: calc(15.05vw);
	--s216: calc(15.12vw);
	--s217: calc(15.19vw);
	--s218: calc(15.26vw);
	--s219: calc(15.33vw);
	--s220: calc(15.4vw);
	--s221: calc(15.47vw);
	--s222: calc(15.54vw);
	--s223: calc(15.61vw);
	--s224: calc(15.68vw);
	--s225: calc(15.75vw);
	--s226: calc(15.82vw);
	--s227: calc(15.89vw);
	--s228: calc(15.96vw);
	--s229: calc(16.03vw);
	--s230: calc(16.1vw);
	--s231: calc(16.17vw);
	--s232: calc(16.24vw);
	--s233: calc(16.31vw);
	--s234: calc(16.38vw);
	--s235: calc(16.45vw);
	--s236: calc(16.52vw);
	--s237: calc(16.59vw);
	--s238: calc(16.66vw);
	--s239: calc(16.73vw);
	--s240: calc(16.8vw);
	--s241: calc(16.87vw);
	--s242: calc(16.94vw);
	--s243: calc(17.01vw);
	--s244: calc(17.08vw);
	--s245: calc(17.15vw);
	--s246: calc(17.22vw);
	--s247: calc(17.29vw);
	--s248: calc(17.36vw);
	--s249: calc(17.43vw);
	--s250: calc(17.5vw);
	--s251: calc(17.57vw);
	--s252: calc(17.64vw);
	--s253: calc(17.71vw);
	--s254: calc(17.78vw);
	--s255: calc(17.85vw);
	--s256: calc(17.92vw);
	--s257: calc(17.99vw);
	--s258: calc(18.06vw);
	--s259: calc(18.13vw);
	--s260: calc(18.2vw);
	--s261: calc(18.27vw);
	--s262: calc(18.34vw);
	--s263: calc(18.41vw);
	--s264: calc(18.48vw);
	--s265: calc(18.55vw);
	--s266: calc(18.62vw);
	--s267: calc(18.69vw);
	--s268: calc(18.76vw);
	--s269: calc(18.83vw);
	--s270: calc(18.9vw);
	--s271: calc(18.97vw);
	--s272: calc(19.04vw);
	--s273: calc(19.11vw);
	--s274: calc(19.18vw);
	--s275: calc(19.25vw);
	--s276: calc(19.32vw);
	--s277: calc(19.39vw);
	--s278: calc(19.46vw);
	--s279: calc(19.53vw);
	--s280: calc(19.6vw);
	--s281: calc(19.67vw);
	--s282: calc(19.74vw);
	--s283: calc(19.81vw);
	--s284: calc(19.88vw);
	--s285: calc(19.95vw);
	--s286: calc(20.02vw);
	--s287: calc(20.09vw);
	--s288: calc(20.16vw);
	--s289: calc(20.23vw);
	--s290: calc(20.3vw);
	--s291: calc(20.37vw);
	--s292: calc(20.44vw);
	--s293: calc(20.51vw);
	--s294: calc(20.58vw);
	--s295: calc(20.65vw);
	--s296: calc(20.72vw);
	--s297: calc(20.79vw);
	--s298: calc(20.86vw);
	--s299: calc(20.93vw);
	--s300: calc(21vw);
	--s310: calc(21.53vw);
	--s320: calc(22.22vw);
	--s340: calc(23.61vw);
	--s368: calc(25vw);
	--s370: calc(25.694vw);
	--s375: calc(26.04vw);
	--s390: calc(27.08vw);
	--s421: calc(29.24vw);
	--s450: calc(31.25vw);
	--s480: calc(33.73vw);
	--s506: calc(35.14vw);
	--s1312: calc(91.5vw);
}

@media screen and (max-width: 520px) {
	:root {
		--s1: calc(0.27vw);
		--s2: calc(0.53vw);
		--s3: calc(0.8vw);
		--s4: calc(1.07vw);
		--s5: calc(1.34vw);
		--s6: calc(1.61vw);
		--s7: calc(1.88vw);
		--s8: calc(2.15vw);
		--s9: calc(2.42vw);
		--s10: calc(2.69vw);
		--s11: calc(2.96vw);
		--s12: calc(3.23vw);
		--s13: calc(3.5vw);
		--s14: calc(3.77vw);
		--s15: calc(4.04vw);
		--s16: calc(4.31vw);
		--s17: calc(4.58vw);
		--s18: calc(4.85vw);
		--s19: calc(5.12vw);
		--s20: calc(5.39vw);
		--s21: calc(5.66vw);
		--s22: calc(5.93vw);
		--s23: calc(6.2vw);
		--s24: calc(6.47vw);
		--s25: calc(6.74vw);
		--s26: calc(7.01vw);
		--s27: calc(7.28vw);
		--s28: calc(7.55vw);
		--s29: calc(7.82vw);
		--s30: calc(8.09vw);
		--s31: calc(8.36vw);
		--s32: calc(8.63vw);
		--s33: calc(8.9vw);
		--s34: calc(9.17vw);
		--s35: calc(9.44vw);
		--s36: calc(9.71vw);
		--s37: calc(9.98vw);
		--s38: calc(10.25vw);
		--s39: calc(10.52vw);
		--s40: calc(10.79vw);
		--s41: calc(11.06vw);
		--s42: calc(11.33vw);
		--s43: calc(11.6vw);
		--s44: calc(11.87vw);
		--s45: calc(12.14vw);
		--s46: calc(12.41vw);
		--s47: calc(12.68vw);
		--s48: calc(12.95vw);
		--s49: calc(13.22vw);
		--s50: calc(13.49vw);
		--s51: calc(13.76vw);
		--s52: calc(14.03vw);
		--s53: calc(14.3vw);
		--s54: calc(14.57vw);
		--s55: calc(14.84vw);
		--s56: calc(15.11vw);
		--s57: calc(15.38vw);
		--s58: calc(15.65vw);
		--s59: calc(15.92vw);
		--s60: calc(16.19vw);
		--s61: calc(16.46vw);
		--s62: calc(16.73vw);
		--s63: calc(17vw);
		--s64: calc(17.27vw);
		--s65: calc(17.54vw);
		--s66: calc(17.81vw);
		--s67: calc(18.08vw);
		--s68: calc(18.35vw);
		--s69: calc(18.62vw);
		--s70: calc(18.89vw);
		--s71: calc(19.16vw);
		--s72: calc(19.43vw);
		--s73: calc(19.7vw);
		--s74: calc(19.97vw);
		--s75: calc(20.24vw);
		--s76: calc(20.51vw);
		--s77: calc(20.78vw);
		--s78: calc(21.05vw);
		--s79: calc(21.32vw);
		--s80: calc(21.59vw);
		--s81: calc(21.86vw);
		--s82: calc(22.13vw);
		--s83: calc(22.4vw);
		--s84: calc(22.67vw);
		--s85: calc(22.94vw);
		--s86: calc(23.21vw);
		--s87: calc(23.48vw);
		--s88: calc(23.75vw);
		--s89: calc(24.02vw);
		--s90: calc(24.29vw);
		--s91: calc(24.56vw);
		--s92: calc(24.83vw);
		--s93: calc(25.1vw);
		--s94: calc(25.37vw);
		--s95: calc(25.64vw);
		--s96: calc(25.91vw);
		--s97: calc(26.18vw);
		--s98: calc(26.45vw);
		--s99: calc(26.72vw);
		--s100: calc(26.99vw);
		--s101: calc(27.26vw);
		--s102: calc(27.53vw);
		--s103: calc(27.8vw);
		--s104: calc(28.07vw);
		--s105: calc(28.34vw);
		--s106: calc(28.61vw);
		--s107: calc(28.88vw);
		--s108: calc(29.15vw);
		--s109: calc(29.42vw);
		--s110: calc(29.69vw);
		--s111: calc(29.96vw);
		--s112: calc(30.23vw);
		--s113: calc(30.5vw);
		--s114: calc(30.77vw);
		--s115: calc(31.04vw);
		--s116: calc(31.31vw);
		--s117: calc(31.58vw);
		--s118: calc(31.85vw);
		--s119: calc(32.12vw);
		--s120: calc(32.39vw);
		--s121: calc(32.66vw);
		--s122: calc(32.93vw);
		--s123: calc(33.2vw);
		--s124: calc(33.47vw);
		--s125: calc(33.74vw);
		--s126: calc(34.01vw);
		--s127: calc(34.28vw);
		--s128: calc(34.55vw);
		--s129: calc(34.82vw);
		--s130: calc(35.09vw);
		--s131: calc(35.36vw);
		--s132: calc(35.63vw);
		--s133: calc(35.9vw);
		--s134: calc(36.17vw);
		--s135: calc(36.44vw);
		--s136: calc(36.71vw);
		--s137: calc(36.98vw);
		--s138: calc(37.25vw);
		--s139: calc(37.52vw);
		--s140: calc(37.79vw);
		--s141: calc(38.06vw);
		--s142: calc(38.33vw);
		--s143: calc(38.6vw);
		--s144: calc(38.87vw);
		--s145: calc(39.14vw);
		--s146: calc(39.41vw);
		--s147: calc(39.68vw);
		--s148: calc(39.95vw);
		--s149: calc(40.22vw);
		--s150: calc(40.49vw);
		--s151: calc(40.76vw);
		--s152: calc(41.03vw);
		--s153: calc(41.3vw);
		--s154: calc(41.57vw);
		--s155: calc(41.84vw);
		--s156: calc(42.11vw);
		--s157: calc(42.38vw);
		--s158: calc(42.65vw);
		--s159: calc(42.92vw);
		--s160: calc(43.19vw);
		--s161: calc(43.46vw);
		--s162: calc(43.73vw);
		--s163: calc(44vw);
		--s164: calc(44.27vw);
		--s165: calc(44vw);
		--s166: calc(44.81vw);
		--s167: calc(45.08vw);
		--s168: calc(45.35vw);
		--s169: calc(45.62vw);
		--s170: calc(45.89vw);
		--s171: calc(46.16vw);
		--s172: calc(46.43vw);
		--s173: calc(46.7vw);
		--s174: calc(46.97vw);
		--s175: calc(47.24vw);
		--s176: calc(47.51vw);
		--s177: calc(47.78vw);
		--s178: calc(48.05vw);
		--s179: calc(48.32vw);
		--s180: calc(48.59vw);
		--s181: calc(48.86vw);
		--s182: calc(49.13vw);
		--s183: calc(49.4vw);
		--s184: calc(49.67vw);
		--s185: calc(49.94vw);
		--s186: calc(50.21vw);
		--s187: calc(50.48vw);
		--s188: calc(50.75vw);
		--s189: calc(51.02vw);
		--s190: calc(51.29vw);
		--s191: calc(51.56vw);
		--s192: calc(51.83vw);
		--s193: calc(52.1vw);
		--s194: calc(52.37vw);
		--s195: calc(52.64vw);
		--s196: calc(52.91vw);
		--s197: calc(53.18vw);
		--s198: calc(53.45vw);
		--s199: calc(53.72vw);
		--s200: calc(53.99vw);
		--s201: calc(54.26vw);
		--s202: calc(54.53vw);
		--s203: calc(54.8vw);
		--s204: calc(55.07vw);
		--s205: calc(55.34vw);
		--s206: calc(55.61vw);
		--s207: calc(55.88vw);
		--s208: calc(56.15vw);
		--s209: calc(56.42vw);
		--s210: calc(56.69vw);
		--s211: calc(56.96vw);
		--s212: calc(57.23vw);
		--s213: calc(57.5vw);
		--s214: calc(57.77vw);
		--s215: calc(58.04vw);
		--s216: calc(58.31vw);
		--s217: calc(58.58vw);
		--s218: calc(58.85vw);
		--s219: calc(59.12vw);
		--s220: calc(59.39vw);
		--s221: calc(59.66vw);
		--s222: calc(59.93vw);
		--s223: calc(60.2vw);
		--s224: calc(60.47vw);
		--s225: calc(60.74vw);
		--s226: calc(61.01vw);
		--s227: calc(61.28vw);
		--s228: calc(61.55vw);
		--s229: calc(61.82vw);
		--s230: calc(62.09vw);
		--s231: calc(62.36vw);
		--s232: calc(62.63vw);
		--s233: calc(62.9vw);
		--s234: calc(63.17vw);
		--s235: calc(63.44vw);
		--s236: calc(63.71vw);
		--s237: calc(63.98vw);
		--s238: calc(64.25vw);
		--s239: calc(64.52vw);
		--s240: calc(64.79vw);
		--s241: calc(65.06vw);
		--s242: calc(65.33vw);
		--s243: calc(65.6vw);
		--s244: calc(65.87vw);
		--s245: calc(66.14vw);
		--s246: calc(66.41vw);
		--s247: calc(66.68vw);
		--s248: calc(66.95vw);
		--s249: calc(67.22vw);
		--s250: calc(67.49vw);
		--s251: calc(67.76vw);
		--s252: calc(68.03vw);
		--s253: calc(68.3vw);
		--s254: calc(68.57vw);
		--s255: calc(68.84vw);
		--s256: calc(69.11vw);
		--s257: calc(69.38vw);
		--s258: calc(69.65vw);
		--s259: calc(69.92vw);
		--s260: calc(70.19vw);
		--s261: calc(70.46vw);
		--s262: calc(70.73vw);
		--s263: calc(71vw);
		--s264: calc(71.27vw);
		--s265: calc(71.54vw);
		--s266: calc(71.81vw);
		--s267: calc(72.08vw);
		--s268: calc(72.35vw);
		--s269: calc(72.62vw);
		--s270: calc(72.89vw);
		--s271: calc(73.16vw);
		--s272: calc(73.43vw);
		--s273: calc(73.7vw);
		--s274: calc(73.97vw);
		--s275: calc(74.24vw);
		--s276: calc(74.51vw);
		--s277: calc(74.78vw);
		--s278: calc(75.05vw);
		--s279: calc(75.32vw);
		--s280: calc(75.59vw);
		--s281: calc(75.86vw);
		--s282: calc(76.13vw);
		--s283: calc(76.4vw);
		--s284: calc(76.67vw);
		--s285: calc(76.94vw);
		--s286: calc(77.21vw);
		--s287: calc(77.48vw);
		--s288: calc(77.75vw);
		--s289: calc(78.02vw);
		--s290: calc(78.29vw);
		--s291: calc(78.56vw);
		--s292: calc(78.83vw);
		--s293: calc(79.1vw);
		--s294: calc(79.37vw);
		--s295: calc(79.64vw);
		--s296: calc(79.91vw);
		--s297: calc(80.18vw);
		--s298: calc(80.45vw);
		--s299: calc(80.72vw);
		--s300: calc(80vw);
		--s320: calc(85.33vw);
		--s340: calc(90.67vw);
		--s368: calc(98.13vw);
		--s370: calc(98.667vw);
		--s375: calc(100vw);
		--s390: calc(104vw);
		--s421: calc(112.27vw);
		--s450: calc(120vw);
		--s480: calc(128vw);
		--s506: calc(134.93vw);
		--s1312: calc(349.87vw);
	}
}

@media screen and (min-width: 1439px) {
	:root {
		--s1: 1px;
		--s2: 2px;
		--s3: 3px;
		--s4: 4px;
		--s5: 5px;
		--s6: 6px;
		--s7: 7px;
		--s8: 8px;
		--s9: 9px;
		--s10: 10px;
		--s11: 11px;
		--s12: 12px;
		--s13: 13px;
		--s14: 14px;
		--s15: 15px;
		--s16: 16px;
		--s17: 17px;
		--s18: 18px;
		--s19: 19px;
		--s20: 20px;
		--s21: 21px;
		--s22: 22px;
		--s23: 23px;
		--s24: 24px;
		--s25: 25px;
		--s26: 26px;
		--s27: 27px;
		--s28: 28px;
		--s29: 29px;
		--s30: 30px;
		--s31: 31px;
		--s32: 32px;
		--s33: 33px;
		--s34: 34px;
		--s35: 35px;
		--s36: 36px;
		--s37: 37px;
		--s38: 38px;
		--s39: 39px;
		--s40: 40px;
		--s41: 41px;
		--s42: 42px;
		--s43: 43px;
		--s44: 44px;
		--s45: 45px;
		--s46: 46px;
		--s47: 47px;
		--s48: 48px;
		--s49: 49px;
		--s50: 50px;
		--s51: 51px;
		--s52: 52px;
		--s53: 53px;
		--s54: 54px;
		--s55: 55px;
		--s56: 56px;
		--s57: 57px;
		--s58: 58px;
		--s59: 59px;
		--s60: 60px;
		--s61: 61px;
		--s62: 62px;
		--s63: 63px;
		--s64: 64px;
		--s65: 65px;
		--s66: 66px;
		--s67: 67px;
		--s68: 68px;
		--s69: 69px;
		--s70: 70px;
		--s71: 71px;
		--s72: 72px;
		--s73: 73px;
		--s74: 74px;
		--s75: 75px;
		--s76: 76px;
		--s77: 77px;
		--s78: 78px;
		--s79: 79px;
		--s80: 80px;
		--s81: 81px;
		--s82: 82px;
		--s83: 83px;
		--s84: 84px;
		--s85: 85px;
		--s86: 86px;
		--s87: 87px;
		--s88: 88px;
		--s89: 89px;
		--s90: 90px;
		--s91: 91px;
		--s92: 92px;
		--s93: 93px;
		--s94: 94px;
		--s95: 95px;
		--s96: 96px;
		--s97: 97px;
		--s98: 98px;
		--s99: 99px;
		--s100: 100px;
		--s101: 101px;
		--s102: 102px;
		--s103: 103px;
		--s104: 104px;
		--s105: 105px;
		--s106: 106px;
		--s107: 107px;
		--s108: 108px;
		--s109: 109px;
		--s110: 110px;
		--s111: 111px;
		--s112: 112px;
		--s113: 113px;
		--s114: 114px;
		--s115: 115px;
		--s116: 116px;
		--s117: 117px;
		--s118: 118px;
		--s119: 119px;
		--s120: 120px;
		--s121: 121px;
		--s122: 122px;
		--s123: 123px;
		--s124: 124px;
		--s125: 125px;
		--s126: 126px;
		--s127: 127px;
		--s128: 128px;
		--s129: 129px;
		--s130: 130px;
		--s131: 131px;
		--s132: 132px;
		--s133: 133px;
		--s134: 134px;
		--s135: 135px;
		--s136: 136px;
		--s137: 137px;
		--s138: 138px;
		--s139: 139px;
		--s140: 140px;
		--s141: 141px;
		--s142: 142px;
		--s143: 143px;
		--s144: 144px;
		--s145: 145px;
		--s146: 146px;
		--s147: 147px;
		--s148: 148px;
		--s149: 149px;
		--s150: 150px;
		--s151: 151px;
		--s152: 152px;
		--s153: 153px;
		--s154: 154px;
		--s155: 155px;
		--s156: 156px;
		--s157: 157px;
		--s158: 158px;
		--s159: 159px;
		--s160: 160px;
		--s161: 161px;
		--s162: 162px;
		--s163: 163px;
		--s164: 164px;
		--s165: 165px;
		--s166: 166px;
		--s167: 167px;
		--s168: 168px;
		--s169: 169px;
		--s170: 170px;
		--s171: 171px;
		--s172: 172px;
		--s173: 173px;
		--s174: 174px;
		--s175: 175px;
		--s176: 176px;
		--s177: 177px;
		--s178: 178px;
		--s179: 179px;
		--s180: 180px;
		--s181: 181px;
		--s182: 182px;
		--s183: 183px;
		--s184: 184px;
		--s185: 185px;
		--s186: 186px;
		--s187: 187px;
		--s188: 188px;
		--s189: 189px;
		--s190: 190px;
		--s191: 191px;
		--s192: 192px;
		--s193: 193px;
		--s194: 194px;
		--s195: 195px;
		--s196: 196px;
		--s197: 197px;
		--s198: 198px;
		--s199: 199px;
		--s200: 200px;
		--s201: 201px;
		--s202: 202px;
		--s203: 203px;
		--s204: 204px;
		--s205: 205px;
		--s206: 206px;
		--s207: 207px;
		--s208: 208px;
		--s209: 209px;
		--s210: 210px;
		--s211: 211px;
		--s212: 212px;
		--s213: 213px;
		--s214: 214px;
		--s215: 215px;
		--s216: 216px;
		--s217: 217px;
		--s218: 218px;
		--s219: 219px;
		--s220: 220px;
		--s221: 221px;
		--s222: 222px;
		--s223: 223px;
		--s224: 224px;
		--s225: 225px;
		--s226: 226px;
		--s227: 227px;
		--s228: 228px;
		--s229: 229px;
		--s230: 230px;
		--s231: 231px;
		--s232: 232px;
		--s233: 233px;
		--s234: 234px;
		--s235: 235px;
		--s236: 236px;
		--s237: 237px;
		--s238: 238px;
		--s239: 239px;
		--s240: 240px;
		--s241: 241px;
		--s242: 242px;
		--s243: 243px;
		--s244: 244px;
		--s245: 245px;
		--s246: 246px;
		--s247: 247px;
		--s248: 248px;
		--s249: 249px;
		--s250: 250px;
		--s251: 251px;
		--s252: 252px;
		--s253: 253px;
		--s254: 254px;
		--s255: 255px;
		--s256: 256px;
		--s257: 257px;
		--s258: 258px;
		--s259: 259px;
		--s260: 260px;
		--s261: 261px;
		--s262: 262px;
		--s263: 263px;
		--s264: 264px;
		--s265: 265px;
		--s266: 266px;
		--s267: 267px;
		--s268: 268px;
		--s269: 269px;
		--s270: 270px;
		--s271: 271px;
		--s272: 272px;
		--s273: 273px;
		--s274: 274px;
		--s275: 275px;
		--s276: 276px;
		--s277: 277px;
		--s278: 278px;
		--s279: 279px;
		--s280: 280px;
		--s281: 281px;
		--s282: 282px;
		--s283: 283px;
		--s284: 284px;
		--s285: 285px;
		--s286: 286px;
		--s287: 287px;
		--s288: 288px;
		--s289: 289px;
		--s290: 290px;
		--s291: 291px;
		--s292: 292px;
		--s293: 293px;
		--s294: 294px;
		--s295: 295px;
		--s296: 296px;
		--s297: 297px;
		--s298: 298px;
		--s299: 299px;
		--s300: 300px;
		--s320: 320px;
		--s340: 340px;
		--s368: 368px;
		--s390: 390px;
		--s421: 421px;
		--s480: 480px;
		--s1312: 1312px;
	}
}
