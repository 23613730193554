@use "@/styles" as *;
.wrap {
	display: flex;
	flex-direction: column;
	gap: var(--s8);
	.head {
		display: flex;
		align-items: center;
		gap: var(--s6);
		svg {
			@include svgSize();
		}
	}
	.block {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: var(--s4);
		.item {
			width: 100%;

			border-radius: var(--s16);
			background-color: var(--casino-bg-blue-grey);
			padding: var(--s12) var(--s12) var(--s12) var(--s8);
			display: flex;
			align-items: center;
			gap: var(--s8);
			&::after {
				content: "";
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				background-color: var(--casino-bg-blue-purple-05);

				z-index: -1;
			}
			&.dragging {
				position: relative;
				overflow: hidden;

				&::after {
					z-index: 100;
				}
			}
			&.dragOver {
				// background-color: blue;
			}
			.row {
				display: flex;
				flex: 1;
				align-items: center;
				gap: var(--s12);
				.textArea {
					width: 100%;
				}
			}
		}
	}
	.bottom {
		display: flex;
		justify-content: center;
	}
}
