.wrapper {
	display: flex;
	flex-direction: column;
	gap: var(--s24);
	.header{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
