@use '@/styles' as *;

.receivingBox {
	display: flex;
	align-items: center;
	gap: var(--s12);
	background-color: var(--casino-bg-blue-purple);
	border-radius: var(--s24);
	padding: var(--s16) var(--s16);
	padding-left: var(--s8);
	transition: 0.5s;
	div {
		position: initial;
	}
	&.dragging {
		position: relative;
		overflow: hidden;
		&::after {
			background-color: var(--casino-bg-blue-purple-05);
			z-index: 100;
		}
	}
	&.dragActive {
		&::after {
			z-index: 100;
		}
	}
	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		border-radius: var(--s24);
		z-index: -1;
	}
	.dragPart {
		display: flex;
		align-items: center;
	}
	.contentPart {
		display: flex;
		flex-direction: column;
		flex: 1;
		gap: var(--s12);
		&.isShow {
			gap: 0;
		}
		.headerContent {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.right {
				display: flex;
				align-items: center;
				gap: var(--s12);
				button.isShow {
					transform: rotate(-180deg);
				}
				svg {
					@include svgSize();
				}
				.removeContent {
					cursor: pointer;
				}
			}
		}
		.content {
			overflow: hidden;
			// transition: max-height 0.5s ease-in-out;
			height: max-content;
			// height: 100%;
			transition: 0.5s;
			&.collapsed {
				height: 0px;

				// max-height: 0;
			}
		}
	}
}
