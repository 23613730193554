.wrap {
	display: flex;
	align-items: center;
	gap: var(--s12);
	background-color: var(--casino-white-opacity-1);
	border-radius: 1000px;
	> p {
		padding: var(--s12) var(--s16);
		padding-right: 0;
	}
	> div {
		width: var(--s44);
		height: var(--s44);
		> svg {
			width: var(--s44);
			height: var(--s44);
		}
	}
}
