@use '@/styles' as *;

.container {
	display: flex;
	flex-direction: column;
	gap: var(--s6);
	width: 100%;
	label {
		font-size: var(--s16);
	}
}
.inputWrapper {
	position: relative;
	border-radius: var(--s12);
	border: 1px solid var(--casino-blue-grey-1);
	background-color: transparent;
	display: flex;
	// flex-direction: column;
	align-items: center;
	padding: var(--s12) var(--s16);
	// min-width: var(--s300);
	justify-content: space-between;
	max-height: var(--s48);
	height: var(--s48);
	width: 100%;
	.placeholder {
		color: var(--casino-grey);
		left: var(--s16);
		font-size: var(--s16);
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		&.inactive {
			top: 0;
			transform: translateY(-50%);
			background-color: var(--casino-bg-blue-grey);
			z-index: 10;
			padding: var(--s2) var(--s4);
			font-size: var(--s12);
			border-radius: var(--s8);
		}
	}
	&:focus-within {
		border: 1px solid var(--casino-light-grey);
	}

	.input {
		width: 100%;
		z-index: 20;
		flex: 1;
		background-color: transparent;
		border: none;
		outline: none;
		color: var(--casino-white);
		font-family: var(--onest);
		font-size: var(--s16);
		font-weight: 400;
		line-height: 140%;

		&::placeholder {
			color: var(--casino-grey);
			font-family: var(--onest);
			font-size: var(--s16);
			font-weight: 400;
			line-height: 140%;
		}
		&:focus {
			outline: none;
			border: none;
		}
	}
}

.icon {
	cursor: pointer;
	padding: 0;
	margin: 0;
	svg {
		@include svgSize();
	}
}

.input:focus + .icon {
	svg {
		stroke: var(--casino-white);
	}
}

.error {
	font-size: var(--s12);
	position: absolute;
	bottom: 0;
	transform: translateY(50%);
	left: var(--s16);
	background-color: var(--casino-bg-blue-grey);
	z-index: 10;
	color: var(--casino-red);
	font-family: var(--onest);
	font-weight: 400;
	line-height: 140%;
}

.errorBorder {
	border: 1px solid var(--casino-red) !important;
	margin-bottom: 10px;
}
