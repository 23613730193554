.tabs {
	display: flex;
	align-items: center;
	gap: var(--s6);
	.tab {
		padding: var(--s12) var(--s33);
		border-radius: 1000px;
		border: 1px solid var(--casino-blue-grey-1);
		cursor: pointer;
		p {
			line-height: 100%;
		}
		&.active {
			background-color: var(--casino-blue-grey-1);
		}
	}
}
