@use '@/styles' as *;

.container {
	display: flex;
	flex-direction: column;
	gap: var(--s16);
	padding: var(--s8) 0;
	.empty {
		display: flex;
		align-items: center;
		gap: var(--s6);
		margin-left: var(--s32);
	}
}
