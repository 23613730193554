.wrapForm {
		display: flex;
		flex-direction: column;
		gap: var(--s24);
		.seoCommon {
			display: flex;
			flex-direction: column;
			gap: var(--s12);
		}
        .seoByLang{
            width: 100%;
			display: flex;
			flex-direction: column;
			gap: var(--s12);
			> p {
				display: flex;
				align-items: center;
			}        
        }
		.actions {
			display: flex;
			justify-content: flex-end;
			gap: var(--s32);
		}
	}