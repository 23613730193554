.seo {
	display: flex;
	flex-direction: column;
	gap: var(--s24);
	.inputFile_block {
		display: flex;
		flex-direction: column;
		gap: var(--s6);
		.inputFile {
			// width: var(--s506);
			// height: var(--s200);
			width: max-content;
		}
	}

	.inputs_block {
		display: flex;
		flex-direction: column;
		gap: var(--s16);
		.inputs {
			display: flex;
			flex-direction: column;
			gap: var(--s12);
		}
	}
}
