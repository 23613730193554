@use '@/styles' as *;
.container {
	display: flex;
	flex-direction: column;
	gap: var(--s24);
	.wrap {
		display: flex;
		flex-direction: column;
		gap: var(--s8);
		.header {
			padding: var(--s16);
			display: flex;
			align-items: center;
			gap: var(--s16);
			&.isOpen {
				.arrow {
					transform: rotate(90deg);
				}
			}
			.arrow {
				cursor: pointer;
				svg {
					@include svgSize(var(--s16), var(--s16));
				}
			}

			.right {
				cursor: pointer;
				display: flex;
				align-items: center;
				gap: var(--s8);
				p {
					line-height: 100%;
				}
			}
		}
		.child {
			display: flex;
			flex-direction: column;
			gap: var(--s20);
			padding: 0 var(--s16);
		}
	}
	.selects {
		display: flex;
		flex-direction: column;
		gap: var(--s16);
	}
}
