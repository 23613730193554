.wrap {
	display: flex;
	flex-direction: column;
	gap: var(--s6);
	.blockEdit {
		border-radius: var(--s12);
		border: 1px solid var(--casino-blue-grey-1);
		background-color: transparent;
		padding: var(--s12) var(--s16);
		display: flex;
		flex-direction: column;
		gap: var(--s12);
		.list {
			position: relative;
			display: flex;
			flex-wrap: wrap;
			gap: var(--s4);
			min-height: var(--s20);

			&::before {
				content: "";
				position: absolute;
				width: 100%;
				height: 100%;

				z-index: 100;
			}
			&.editable {
				&::before {
					z-index: -1;
				}
			}
			// height: var(--s30);
			.item {
				padding: var(--s6) var(--s10);
				background-color: var(--casino-white-opacity-hex);
				border-radius: var(--s8);
				font-size: var(--s16);
				color: var(--casino-white);
				display: flex;
				svg {
					width: var(--s20);
					height: var(--s20);
				}
			}
		}
		.rowAdd {
			display: flex;
			align-items: center;
			gap: var(--s8);
		}
	}
}
