.container {
	max-width: 90%;
	max-height: 90%;
	border-radius: var(--s40);
	padding: var(--s40);
	background-color: var(--casino-bg-blue-grey);
	border-radius: var(--s40);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: var(--s20);
}
