@use "@/styles" as *;

.title {
	color: var(--casino-white);

	&.m {
		font-weight: 600;
		font-size: var(--s20);
		line-height: 120%;
	}

	&.l {
		text-transform: uppercase;
		font-weight: 500;
		font-size: var(--s28);
		line-height: 120%;
	}
}

@include mobile {
	.title {
		&.m {
			font-size: var(--s18);
		}

		&.l {
			font-size: var(--s24);
		}
	}
}
