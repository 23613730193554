.loader {
    border: var(--s4)solid var(--casino-bg-blue-grey); 
    border-left-color: var(--casino-green); 
    border-radius: 50%;
    width: var(--s40); 
    height: var(--s40); 
    animation: spin 1s linear infinite;
    margin: auto; 
}

@keyframes spin {
    0% {
        transform: rotate(0deg); 
    }
    100% {
        transform: rotate(360deg);
    }
}
