@import "theme/colors";
@import "theme/fonts";

html {
	scroll-behavior: smooth;
}

*,
*::before,
*::after {
	box-sizing: border-box;
	transition: 0.3s;
}

ul,
ol {
	list-style: none;
	margin: 0;
	padding: 0;
}

li {
	list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: inherit;
	font-weight: 400;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
figure,
figcaption,
blockquote,
dl,
dd {
	margin: 0;
}

body {
	font-family: var(--onest);
	font-weight: 400;
	font-style: normal;
	scroll-behavior: smooth;
	text-rendering: optimizeSpeed;
	line-height: 1.5;
	color: var(--casino-white);
	// user-select: none;
	// font-size: var(--s16);
	background-color: var(--casino-bg-blue-grey);
}

html,
body {
	width: 100%;
	line-height: 1;
	font-size: 16px;
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

a {
	display: inline-block;
	text-decoration-skip-ink: auto;
	text-decoration: none;
}
a:hover {
	text-decoration: none;
}

img {
	max-width: 100%;
	display: block;
}

article > * + * {
	margin-top: 1em;
}

input,
button,
textarea,
select {
	font: inherit;
	outline: none;
	border: none;
}

button {
	padding: 0;
	background-color: inherit;
	border: none;
	font: inherit;
	outline: none;
}

button {
	cursor: pointer;
}
button::-moz-focus-inner {
	padding: 0;
	border: none;
}

input::-ms-clear {
	display: none;
}

figure {
	display: block;
	margin-block-start: 0;
	margin-block-end: 0;
	margin-inline-start: 0;
	margin-inline-end: 0;
}

a:active,
a:hover,
a {
	text-decoration: none;
	color: black;
}

div {
	position: relative;
	box-sizing: border-box;
}
